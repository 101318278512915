import React, { useState, useRef } from 'react';
import PartnerCarousel from './partnerCarousel';
import Footer from './footer';
import FAQ from './faq';
import Header from './header';
import { useNavigate } from 'react-router-dom';

const Contact = () => {

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        whatsapp: "",
        message: "",
    });

    const [error, setError] = useState(false);

    const handleChange = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setError(false);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const { fullName, email, whatsapp, message } = formData;
        if (!fullName || !email || !whatsapp || !message) {
            setError(true);
        } else {
            // Lógica de envio (ex: API)
            console.log("Formulário enviado:", formData);
            alert("Formulário enviado com sucesso!");
            setFormData({
                fullName: "",
                email: "",
                whatsapp: "",
                message: "",
            });
        }
    };

    return (
        <div className="flex flex-col w-[100%] h-[100%] dark:bg-sercredSecondBlueDark dark:text-white">
            <div id='inicio'>
                <Header />
            </div>
            <div className='flex flex-col lg:mb-10 text-sercredDarkBlue dark:bg-sercredSecondBlueDark dark:text-white'>
                <div className='flex flex-col items-center p-6 lg:items-start lg:pl-40'>
                    <h1 className='text-2xl mb-5 lg:text-5xl lg:mb-8'>Entre em contato conosco</h1>
                    <p className='text-md text-center lg:text-2xl lg:w-[55%] lg:text-start'>Gostaria de fechar uma parceria ou tem alguma dúvida? Entre em contato conosco pelos canais abaixo, e um de nossos especialistas irá atendê-lo!</p>
                </div>
                <div className='flex flex-col w-[100%] h-[100%] lg:flex-row lg:items-start lg:mb-10 lg:mt-10 lg:pl-40'>
                    <div className='flex dark:text-white flex-col items-center justify-center mt-10 lg:items-start lg:mr-32 lg:mt-0'>
                        <img src="whatsapp 7.png" alt="" className='w-[30%] lg:w-[30%]' />
                        <p className='font-semibold mt-5 mb-3 lg:text-2xl'>WhatsApp </p>
                        <p className='text-[#001f40] dark:text-white font-bold flex'><span className='font-normal text-[#001f40] dark:text-white mr-1'>Suporte: </span> (12) 99703-2407 <a
                            href="https://wa.me/5512997032407"
                            target="_blank"
                            rel="noopener noreferrer"
                            className=""
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" className="w-4 h-4  -rotate-45">
                                <path d="M10.828 5.172a.5.5 0 0 1 .707-.707l3 3a.5.5 0 0 1 0 .707l-3 3a.5.5 0 1 1-.707-.707L12.793 8.5H5.5a.5.5 0 0 1 0-1h7.293l-1.965-1.965Z" />
                            </svg>
                        </a></p>
                        <p className='text-[#001f40] dark:text-white  font-bold flex'><span className='font-normal text-[#001f40] dark:text-white mr-1'>Comercial: </span> (12) 99770-5801 <a
                            href="https://wa.me/5512997705801"
                            target="_blank"
                            rel="noopener noreferrer"
                            className=""
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" className="w-4 h-4  -rotate-45">
                                <path d="M10.828 5.172a.5.5 0 0 1 .707-.707l3 3a.5.5 0 0 1 0 .707l-3 3a.5.5 0 1 1-.707-.707L12.793 8.5H5.5a.5.5 0 0 1 0-1h7.293l-1.965-1.965Z" />
                            </svg>
                        </a></p>
                    </div>
                    <div className='flex dark:text-white flex-col justify-center items-center mb-10 mt-10 lg:items-start lg:m-0'>
                        <img src="image 147.png" alt="" className='w-[30%] lg:w-[22%]' />
                        <p className='font-semibold mt-5 mb-3 lg:text-2xl'>E-mail</p>
                        <p className='text-[#001f40] dark:text-white  font-bold flex'><span className='font-normal text-[#001f40] dark:text-white mr-1'>Suporte: </span> suporte@sercred.com <a
                            href="mailto:suporte@sercred.com"
                            className="ml-1"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" className="w-4 h-4  -rotate-45">
                                <path d="M10.828 5.172a.5.5 0 0 1 .707-.707l3 3a.5.5 0 0 1 0 .707l-3 3a.5.5 0 1 1-.707-.707L12.793 8.5H5.5a.5.5 0 0 1 0-1h7.293l-1.965-1.965Z" />
                            </svg>
                        </a></p>
                        <p className='text-[#001f40] dark:text-white  font-bold flex'><span className='font-normal text-[#001f40] dark:text-white mr-1'>Comercial: </span> comercial@sercred.com <a
                            href="mailto:comerciale@sercred.com"
                            className="ml-1"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" className="w-4 h-4  -rotate-45">
                                <path d="M10.828 5.172a.5.5 0 0 1 .707-.707l3 3a.5.5 0 0 1 0 .707l-3 3a.5.5 0 1 1-.707-.707L12.793 8.5H5.5a.5.5 0 0 1 0-1h7.293l-1.965-1.965Z" />
                            </svg>
                        </a></p>
                    </div>
                    {/* <div className='flex flex-col justify-center items-center mb-10 lg:items-start lg:m-0'>
                        <img src="telefone-velho 1.png" alt="" className='w-[30%] lg:w-[30%]' />
                        <p className='font-semibold mt-5 mb-3 lg:text-2xl'>Telefone</p>
                        <p className='text-[#001f40] font-bold'><span className='font-normal text-[#001f40]'>Suporte: </span>(12) 99703-2407</p>
                        <p className='text-[#001f40] font-bold'><span className='font-normal text-[#001f40]'>Comercial: </span>(12) 99770-5801</p>
                    </div> */}
                </div>
            </div>
            <div className='lg:flex-row bg-sercredLightBlue lg:justify-evenly flex flex-col pt-10 pb-10 pl-5 pr-5'>
                <div className='flex flex-col lg:w-[40%] lg:mt-16 lg:mb-10'>
                    <h1 className='text-white lg:text-5xl text-3xl'>Ou nos envie a sua dúvida através do seguinte formulário</h1>
                    <p className='text-white lg:text-3xl lg:mt-10 lg:mb-10 lg:w-[90%] text-xl mb-5'>E em breve um dos nossos especialistas entrará em contato para responder as suas dúvidas!</p>
                    <div className='lg:flex w-[100%] h-[15%] justify-evenly mb-8 md:w-[20%] hidden'>
                        <div>
                            <a target='_blank' href="https://www.instagram.com/sercredconvenio?igsh=ZWloMHRwaXFqcTB2"><img src="instagram 7.svg" alt="" /></a>
                        </div>
                        <div>
                            <a target='_blank' href="#https://www.linkedin.com/company/sercredconvenio/"><img src="linkedin 2.svg" alt="" /></a>
                        </div>
                        <div>
                            <a target='_blank' href="https://web.facebook.com/profile.php?id=61570547095625"><img src="facebook 7.svg" alt="" /></a>
                        </div>
                    </div>
                </div>
                <div className='flex lg:w-[50%] lg:justify-center lg:mb-10 lg:mt-16'>
                    <form
                        onSubmit={handleSubmit}
                        className="w-[100%] flex flex-col bg-white p-6 rounded-lg shadow-md"
                    >
                        <div className="relative border-b-2 border-sercredDarkBlue lg:mt-10 lg:mb-12 lg:pb-2 mt-5 mb-5">
                            <input
                                type="text"
                                name="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                                placeholder="Digite o seu nome completo"
                                className="w-full bg-transparent outline-none text-gray-700 placeholder-gray-400"
                            />
                        </div>
                        <div className="relative border-b-2 border-sercredDarkBlue lg:mb-12 lg:pb-2 mt-5 mb-5">
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Digite o seu melhor e-mail"
                                className="w-full bg-transparent outline-none text-gray-700 placeholder-gray-400"
                            />
                        </div>
                        <div className="relative border-b-2 border-sercredDarkBlue lg:mb-12 lg:pb-2 mt-5 mb-5">
                            <input
                                type="text"
                                name="whatsapp"
                                value={formData.whatsapp}
                                onChange={handleChange}
                                placeholder="Digite o seu whatsapp"
                                className="w-full bg-transparent outline-none text-gray-700 placeholder-gray-400"
                            />
                        </div>
                        <div className="relative border-b-2 border-sercredDarkBlue lg:mb-5 lg:pb-2 mt-5 mb-5">
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                placeholder="Escreva sua dúvida aqui"
                                rows={4}
                                className="w-full bg-transparent outline-none text-gray-700 placeholder-gray-400 resize-none"
                            ></textarea>
                        </div>
                        {error && (
                            <p className="text-red-500 text-sm">
                                Todos os campos precisam ser preenchidos.
                            </p>
                        )}
                        <button type='submit' className='border text-sercredDarkBlue border-1px border-sercredDarkBlue p-3 rounded-lg lg:w-[30%] w-[75%] flex items-center justify-evenly'>Enviar formulário  <img src="Component 200.svg" alt="image-1" /></button>
                    </form>
                </div>
            </div>
            <div>
                <FAQ />
            </div>
            <div className='flex w-[100%] h-[100%]'>
                <Footer />
            </div>
        </div>
    );
};

export default Contact;