import React from "react";
import Footer from "./footer";
import Header from "./header";
import { useNavigate } from 'react-router-dom';

const StoreCard = () => {

    const navigate = useNavigate();

    const handleNavigate = (path: string, id?: string) => {
        navigate(path);
        if (id) {
            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) element.scrollIntoView({ behavior: 'smooth' });
            }, 100); 
        }
    };

    return (
        <div className="flex flex-col w-[100%] h-[100%]  text-sercredDarkBlue overflow-x-hidden">
            <div id="inicio">
                <Header />
            </div>
            <div className="flex dark:bg-sercredSecondBlueDark dark:text-white flex-col w-[100%] h-[100%] pt-5 pb-5 lg:flex-row lg:justify-evenly lg:items-center">
                <div className="flex flex-col p-5 lg:w-[40%]">
                    <h1 className="text-3xl mb-3 lg:text-5xl lg:mb-8">O cartão com sua marca e exclusivo para sua loja</h1>
                    <p className="mb-5 lg:text-2xl lg:mb-8">Transforme sua empresa na primeira opção de compra de milhares de consumidores com nossa solução de private label e ofereça um cartão exclusivo com a sua marca para que seus clientes o utilizem nos seus estabelecimentos.</p>
                    <button onClick={() => handleNavigate('/contact', 'inicio')} className='border border-1px border-sercredDarkBlue p-3 rounded-lg lg:w-[45%] w-[65%] flex items-center justify-evenly'>Quero entender melhor  <img src="Component 200.svg" alt="image-1" /></button>
                </div>
                <div className="p-5">
                    <img src="sigacred.png" alt="image-1" className='lg:h-[100%] relative -right-20' />
                </div>
            </div>
            <div className="flex flex-col dark:bg-sercredDarkBlueDark dark:text-white w-[100%] h-[100%] bg-[#5da0c4d3] pt-5 pb-5 lg:flex-row-reverse lg:justify-evenly lg:items-center">
                <div className="flex flex-col p-5 lg:w-[40%]">
                    <h1 className="text-3xl mb-3 lg:text-5xl lg:mb-8">Faça parte da nossa rede credenciada</h1>
                    <p className=" mb-5 lg:text-2xl lg:mb-8">Cadastre o seu estabelecimento para aceitar pagamentos através da sercred e de nossos parceiros e ainda tenha um cartão personalizado com a sua marca!</p>
                    <button onClick={() => handleNavigate('/contact', 'inicio')} className='border border-1px border-sercredDarkBlue p-3 rounded-lg lg:w-[45%] w-[75%] flex items-center justify-evenly'>Quero cadastrar minha loja  <img src="Component 200.svg" alt="image-1" /></button>
                </div>
                <div className="lg:relative lg:-left-10">
                    <img src="mapa.svg" alt="image-1" className='lg:h-[100%]' />
                </div>
            </div>
            <div className="flex flex-col dark:bg-sercredSecondBlueDark dark:text-white w-[100%] h-[100%] pt-5 pb-5 lg:flex-row lg:justify-evenly lg:items-center">
                <div className="flex flex-col p-5 lg:w-[40%]">
                    <h1 className="text-3xl mb-3 lg:text-5xl lg:mb-8">Tenha um aplicativo com a sua marca para seu cliente</h1>
                    <p className=" mb-5 lg:text-2xl lg:mb-8">Seus clientes terão acesso a um aplicativo personalizado com a sua marca para consultar saldos, limites, faturas e muito mais. Além disso, você também contará com uma plataforma exclusiva para gerenciar tudo de forma eficiente.</p>
                    <button onClick={() => handleNavigate('/contact', 'inicio')} className='border border-1px border-sercredDarkBlue p-3 rounded-lg lg:w-[65%] w-[100%] flex items-center justify-evenly'>Quero os benefícios para minha empresa  <img src="Component 200.svg" alt="image-1" /></button>
                </div>
                <div className="p-5">
                    <img src="Component 201.svg" alt="image-1" className='lg:h-[100%]' />
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
};


export default StoreCard;