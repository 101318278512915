export const faqData: { [category: string]: { id: number; question: string; answer: string }[] } = {
    Parcerias: [
        { id: 1, question: "Quais tipos de parcerias a Sercred oferece?", answer: "A Sercred oferece diversos tipos de parcerias para atender às necessidades de diferentes públicos. São elas: Para empresas que desejam personalizar o sistema com sua marca, credenciar outras empresas e criar sua própria rede de convênios; empresas que desejam liberar nossos convênios e benefícios para seus colaboradores; Lojas, mercados, farmácias e outros locais que aceitam o convênio Sercred como forma de pagamento; Colaboradores que acessam nossos serviços e benefícios diretamente pelo aplicativo; Empresas que desejam implementar soluções de financiamento, PIX parcelado e outros serviços financeiros; Negócios que desejam oferecer cartões exclusivos com sua marca para fidelizar clientes; Profissionais ou empresas que promovem e vendem nossas soluções... " },
        { id: 2, question: "Como funciona a parceria para empresas que desejam um sistema de convênio próprio?", answer: "Empresas que optam por essa parceria podem personalizar nosso sistema com sua marca, criar sua própria rede credenciada e oferecer convênios exclusivos. Isso inclui o credenciamento de outras empresas, possibilitando a criação de um ecossistema de benefícios personalizado." },
        { id: 3, question: "Minha empresa pode oferecer o convênio Sercred aos colaboradores?", answer: "Sim! Para isso, é necessário se credenciar conosco. Após a aprovação, seus colaboradores terão acesso a uma série de benefícios, como Saque Rotativo, Antecipação Salarial, Empréstimos Consignados e muito mais, diretamente pelo aplicativo." },
        { id: 4, question: "Como posso credenciar minha loja para aceitar o convênio Sercred?", answer: "O processo é simples: Entre em contato com nosso time de especialistas, preencha nosso formulário de credenciamento e por último, Após a análise e aprovação, sua empresa estará apta a aceitar pagamentos através do convênio Sercred e de nossos parceiros." },
        { id: 5, question: "Quem são os correspondentes e como eles trabalham com a Sercred?", answer: "Correspondentes são profissionais ou empresas que promovem e comercializam nossas soluções, como convênios, financiamentos e o Parcelex. Eles atuam como nossos representantes, conectando clientes às nossas soluções e ampliando nossa rede de atendimento." },
        { id: 6, question: "Como funciona a parceria para clientes Parcelex?", answer: "Clientes Parcelex têm acesso a soluções financeiras personalizadas, como financiamento para seus clientes e PIX parcelado. Nossa equipe auxilia na implementação dessas soluções, oferecendo suporte técnico e estratégico." },
        { id: 7, question: "É possível personalizar um cartão com a marca da minha empresa?", answer: "Sim! Nossa solução de Cartão Private Label permite que você tenha cartões com a sua marca, usados exclusivamente nos seus estabelecimentos. Essa é uma ótima forma de fidelizar seus clientes e reforçar sua identidade de marca." },
        { id: 8, question: "Minha empresa pode atuar como parceira em mais de um modelo?", answer: "Com certeza! Você pode, por exemplo, ser um estabelecimento credenciado para aceitar o convênio Sercred e também oferecer a solução Parcelex para financiamentos ou PIX parcelado. Nossa equipe irá auxiliar na integração de todas as soluções necessárias." },
        { id: 9, question: "Quais são os benefícios de se tornar um parceiro da Sercred?", answer: "Ao se tornar parceiro, você terá acesso a: soluções personalizadas para sua empresa, expansão da sua rede de clientes e colaboradores, ferramentas tecnológicas intuitivas, como aplicativos e plataformas de gestão, suporte especializado para cada etapa da parceria e muito mais!" },

    ],
    Plataformas: [
        { id: 10, question: "Quais plataformas a Sercred oferece?", answer: "Plataforma para parceiros que possuem um sistema de convênio próprio, com gestão completa da sua rede, empresas que contratam o convênio para seus colaboradores, com ferramentas de gestão e relatórios, lojas e comércios que aceitam o convênio, com funcionalidades para acompanhar transações e extratos, profissionais que comercializam nossas soluções, com controle de vendas e suporte, colaboradores que acessam os serviços e benefícios diretamente pelo celular, para parceiros que querem colocar novos meios de pagamento, como PIX parcelado e financiamento para os clientes, para empresas que desejam gerenciar cartões exclusivos com a sua marca. Também inclui um aplicativo para clientes, que permite o acesso a saldo, limites, faturas e mais..." },
        { id: 11, question: "Todas as plataformas são personalizáveis?", answer: "Sim! As plataformas da Sercred são white label, permitindo que você as personalize com a marca, cores e identidade visual da sua empresa." },
        { id: 12, question: "O que a Plataforma do Parceiro oferece?", answer: "Gestão de empresas credenciadas, acompanhamento de transações, Controle e personalização do sistema de convênio próprio dentre outras funcionalidades." },
        { id: 13, question: "O que está disponível na Plataforma do Cliente Corporativo?", answer: "Essa plataforma permite que empresas acompanhem e gerenciem os convênios oferecidos aos seus colaboradores, com ferramentas para: controle de adesões, relatórios de uso, suporte personalizado..." },
        { id: 14, question: "Como funciona a Plataforma do Estabelecimento Credenciado?", answer: "Estabelecimentos que aceitam o convênio têm acesso a: histórico de transações realizadas, gerenciamento de pagamentos recebidos, suporte para dúvidas ou problemas técnicos, etc." },
        { id: 15, question: "O que posso fazer no Aplicativo do Colaborador?", answer: "O aplicativo white label para colaboradores permite que eles: consultem saldo e limites, realizem saques rotativos, antecipação salarial, empréstimos consignados, acompanhem faturas e transações, etc. " },
        { id: 16, question: "Qual a funcionalidade da Plataforma Parcelex?", answer: "A Plataforma Parcelex é voltada para empresas que desejam implementar soluções financeiras como financiamento e PIX parcelado. Ela permite: Configurar prazos e condições de pagamento, gerenciar transações e contratos realizados, oferecer uma solução prática e rápida para os clientes, simular os financiamentos e enviar links de pagamento de forma simples..." },
        { id: 17, question: "Como funciona a Plataforma do Cartão Private Label?", answer: "A plataforma permite que empresas gerenciem o uso dos seus cartões exclusivos, com funcionalidades como: Emissão e controle de cartões, gestão de limites e faturas, relatórios detalhados sobre o uso e muito mais" },
        { id: 18, question: "O que está disponível no Aplicativo do Cliente do Cartão Private Label?", answer: "Os clientes que possuem um cartão Private Label têm acesso a: Consulta de saldo e limite disponível, visualização de faturas e histórico de compras, notificações personalizadas e suporte..." },
        { id: 19, question: "É possível integrar várias plataformas em um único contrato?", answer: "Sim! Dependendo das necessidades da sua empresa, podemos oferecer uma integração entre várias plataformas, facilitando a gestão e oferecendo uma experiência unificada para todos os envolvidos." },
        { id: 20, question: "Todas as plataformas possuem suporte técnico?", answer: "Sim! Todas as nossas plataformas contam com suporte especializado, garantindo que você tenha assistência em caso de dúvidas ou problemas técnicos." },
    ],
    Convênio: [
        { id: 21, question: "O convênio empresarial da Sercred é apenas um convênio de saúde?", answer: "Não! O convênio empresarial da Sercred vai muito além de um convênio de saúde. É uma solução completa que permite que os colaboradores utilizem diversos benefícios, como compras em mercados, farmácias, e outros estabelecimentos credenciados, com desconto direto em folha de pagamento." },
        { id: 22, question: "Quais benefícios o convênio empresarial oferece aos colaboradores?", answer: "Os colaboradores podem aproveitar: Compras em uma ampla rede credenciada, como farmácias, mercados e lojas, acesso a soluções financeiras, como Saque Rotativo, Antecipação Salarial e Empréstimos Consignados, PIX Parcelado e outras facilidades para organizar suas finanças, Um aplicativo personalizado para consultar saldo, limite e muito mais" },
        { id: 23, question: "Como funciona o desconto em folha?", answer: "Todas as transações realizadas pelos colaboradores através do convênio são consolidadas e descontadas diretamente na folha de pagamento no mês seguinte, sem custos extras para a empresa ou complicações para o colaborador." },
        { id: 24, question: "O colaborador tem algum custo para utilizar o convênio?", answer: "Não. O convênio empresarial da Sercred não gera custos diretos para o colaborador. Apenas os valores utilizados são descontados diretamente em folha." },
        { id: 25, question: "O convênio pode ser personalizado?", answer: "Sim! O convênio é totalmente adaptável às necessidades da sua empresa, permitindo incluir benefícios específicos ou restringir o uso a determinados tipos de transações." },
        { id: 26, question: "Quais estabelecimentos fazem parte da rede credenciada?", answer: "A rede credenciada inclui uma ampla variedade de estabelecimentos, como: Supermercados, Farmácias, Lojas de vestuário e eletrodomésticos, Postos de combustíveis e muito mais. A lista é continuamente expandida para oferecer ainda mais opções aos colaboradores." },
        { id: 27, question: "Como os colaboradores utilizam o convênio?", answer: "Os colaboradores podem utilizar o convênio apresentando o cartão físico ou digital, disponível no aplicativo, em qualquer estabelecimento credenciado." },
        { id: 28, question: "Quais as vantagens para a empresa ao oferecer o convênio empresarial?", answer: "Atração e retenção de talentos: Benefícios valorizados pelos colaboradores; Melhoria na qualidade de vida dos colaboradores: Acesso a soluções financeiras e uma rede ampla de serviços..." },
        { id: 29, question: "Como a empresa faz a adesão ao convênio?", answer: "Basta entrar em contato com a nossa equipe de especialistas, que irá orientá-lo em todas as etapas do processo, desde a contratação até a configuração do convênio." },
        { id: 30, question: "O convênio empresarial está disponível para empresas de todos os tamanhos?", answer: "Sim! O convênio é flexível e pode ser implementado em empresas de diferentes portes, oferecendo soluções sob medida para cada necessidade." },
        
    ],
    Saque: [
        { id: 31, question: "O que é o Saque Rotativo?", answer: "O Saque Rotativo é um benefício que permite ao colaborador transformar parte do limite disponível no cartão convênio em crédito direto na conta bancária, oferecendo maior flexibilidade para situações de emergência ou organização financeira no final do mês." },
        { id: 32, question: "O que é o Saque Parcelado (Antecipação Salarial)?", answer: "O Saque Parcelado, também chamado de Antecipação Salarial, permite ao colaborador antecipar até 3 vezes o valor do limite mensal do cartão convênio e transformá-lo em crédito bancário. O valor é pago em parcelas descontadas diretamente na folha de pagamento, facilitando a quitação sem comprometer o orçamento." },
        { id: 33, question: "Qual a diferença entre o Saque Rotativo e o Saque Parcelado?", answer: "Saque Rotativo: Oferece flexibilidade para retirar um valor dentro do limite do cartão convênio, sem necessidade de parcelamento. O valor é descontado integralmente na folha do próximo mês. Saque Parcelado (Antecipação Salarial): Permite a antecipação de valores maiores, até 3 vezes o limite do cartão, com a opção de dividir o pagamento em parcelas, proporcionando mais prazo para o colaborador organizar suas finanças." },
        { id: 34, question: "Como o colaborador pode solicitar o saque?", answer: "A solicitação é feita de forma simples e ágil através do aplicativo personalizado da Sercred. O colaborador escolhe o tipo de saque, define o valor e, no caso do Saque Parcelado, seleciona o número de parcelas." },
        { id: 35, question: "O dinheiro cai na conta bancária de qual banco?", answer: "O valor pode ser transferido para qualquer conta bancária cadastrada pelo colaborador durante o processo de solicitação, oferecendo total liberdade na escolha." },
        { id: 36, question: "Quais as vantagens para o colaborador?", answer: "Acesso rápido e prático a crédito em momentos de necessidade, opções flexíveis de pagamento, com ou sem parcelamento, gestão simplificada através do aplicativo, sem burocracia..." },
        { id: 37, question: "O saque compromete todo o limite do cartão convênio?", answer: "Sim, o valor utilizado no saque (seja Rotativo ou Parcelado) será descontado do limite disponível no cartão convênio. O limite será restabelecido conforme os valores forem pagos." },
        { id: 38, question: "O colaborador pode utilizar ambos os tipos de saque?", answer: "Sim. Desde que tenha limite disponível, o colaborador pode utilizar tanto o Saque Rotativo quanto o Parcelado, de acordo com sua necessidade e preferências financeiras." },
    ],
    Empréstimo: [
        { id: 39, question: "O que é o Empréstimo Consignado?", answer: "O Empréstimo Consignado é uma linha de crédito oferecida aos colaboradores, com pagamento das parcelas descontado diretamente na folha de pagamento, oferecendo taxas mais baixas, prazos flexíveis e facilidade no processo de contratação." },
        { id: 40, question: "Quem pode solicitar o Empréstimo Consignado?", answer: "Qualquer colaborador de uma empresa que tenha contrato com a Sercred e disponibilize o benefício do consignado pode solicitar o empréstimo, desde que esteja dentro das condições estabelecidas, como possuir margem consignável disponível." },
        { id: 41, question: "Como funciona o desconto em folha?", answer: "As parcelas do empréstimo são automaticamente descontadas do salário do colaborador todos os meses, garantindo praticidade no pagamento, sem a necessidade de boletos ou ações adicionais." },
        { id: 42, question: "O Empréstimo Consignado tem limite de valor?", answer: "Sim, o valor do empréstimo é calculado com base na margem consignável disponível para cada colaborador, respeitando os limites legais e evitando comprometer o orçamento do funcionário." },
        { id: 43, question: "Quais as vantagens do Empréstimo Consignado?", answer: "O empréstimo oferece taxas de juros reduzidas, prazos maiores para pagamento, conveniência no desconto em folha e processo de aprovação mais rápido em comparação a outras modalidades de crédito." },
        { id: 44, question: "Como o colaborador pode simular e contratar o empréstimo?", answer: "O colaborador pode acessar o aplicativo personalizado da Sercred, realizar simulações com diferentes valores e prazos, verificar o impacto das parcelas no salário e concluir a solicitação de forma totalmente digital." },
        { id: 45, question: "O empréstimo é limitado a uma única solicitação?", answer: "Não, o colaborador pode realizar novas solicitações de empréstimo, desde que ainda tenha margem consignável disponível e atenda aos critérios estabelecidos." },
        { id: 46, question: "Quais documentos são necessários para solicitar o empréstimo?", answer: "Normalmente, o colaborador precisa apenas ter os dados atualizados na empresa, já que a verificação é feita diretamente pelo sistema integrado à folha de pagamento." },
        { id: 47, question: "Quanto tempo demora para o dinheiro estar disponível na conta?", answer: "Após a aprovação, o valor é transferido para a conta bancária cadastrada pelo colaborador, geralmente em até 48 horas úteis." },
    ],
    Parcelex: [
        { id: 48, question: "O que é a Parcelex?", answer: "A Parcelex é uma solução que permite a implementação de financiamentos e PIX parcelado para empresas, oferecendo aos clientes mais flexibilidade no pagamento de compras e serviços, com opções acessíveis e fáceis de usar." },
        { id: 49, question: "Como funciona o PIX Parcelado?", answer: "O pagamento das parcelas da compra são enviadas para o cliente todos os meses e podem ser pagas pelo boleto, ou pelo PIX, não sendo necessário ter limite de crédito, ou comprometer o mesmo." },
        { id: 50, question: "Quais empresas podem utilizar a Parcelex?", answer: "Qualquer empresa que deseje oferecer mais opções de pagamento aos seus clientes pode integrar a Parcelex, desde comércios menores até grandes redes varejistas, estando sujeitos a uma análise prévia para a aprovação." },
        { id: 51, question: "Quais são os benefícios para minha empresa?", answer: "Ao implementar a Parcelex, sua empresa amplia as opções de pagamento, atrai mais clientes, aumenta o ticket médio de compras e fortalece o relacionamento com os consumidores, tudo com integração fácil e rápida." },
        { id: 52, question: "Como a minha empresa pode oferecer o financiamento Parcelex?", answer: "A empresa precisa contratar a solução e integrar a plataforma Parcelex em seu sistema, permitindo que os clientes simulem e contratem financiamentos diretamente na loja" },
        { id: 53, question: "Quem define as condições do financiamento ou do parcelamento?", answer: "Quando a compra é simulada no sistema, aparecem as opções para que o seu cliente escolha qual se encaixa melhor para ele." },
        { id: 54, question: "A Sercred oferece suporte para a empresa?", answer: "Sim, tanto a Sercred quanto a parcelex oferecem o suporte que inclui desde a integração da plataforma até o acompanhamento do desempenho da solução, garantindo que sua empresa tire o máximo proveito da Parcelex." },
        { id: 55, question: "Como faço para contratar a Parcelex?", answer: "Entre em contato com nosso time de especialistas ou preencha o formulário no site. Vamos entender as necessidades do seu negócio e guiar você em todo o processo de contratação e implementação." },
    ],
    Private: [
        { id: 56, question: "O que é o Cartão Loja / Private Label?", answer: "O Cartão Loja, também conhecido como Private Label, é uma solução personalizada para empresas que desejam oferecer um cartão exclusivo com sua marca. Ele pode ser utilizado pelos clientes para realizar compras nos estabelecimentos da empresa, oferecendo vantagens e fidelizando consumidores." },
        { id: 57, question: "Como funciona o Cartão Loja?", answer: "Os clientes utilizam o cartão com a marca da empresa para realizar compras, podendo acessar benefícios exclusivos, como descontos e condições especiais de pagamento. As transações podem ser gerenciadas pela empresa por meio de uma plataforma personalizada." },
        { id: 58, question: "Quais empresas podem ter um Cartão Loja?", answer: "Qualquer empresa que deseja fortalecer sua marca, fidelizar clientes e aumentar o ticket médio de compras pode adotar um Cartão Loja. Ele é especialmente vantajoso para redes de varejo, supermercados, farmácias e lojas especializadas." },
        { id: 59, question: "Quais são os benefícios do Cartão Loja para a minha empresa?", answer: "Sua empresa se torna a principal opção de compra para seus clientes, aumenta a fidelização, fortalece o branding com o uso da sua marca no cartão e nos aplicativos, além de oferecer mais conveniência e vantagens aos consumidores." },
        { id: 60, question: "O Cartão Loja oferece alguma vantagem para o cliente?", answer: "Sim, os clientes podem acessar condições de pagamento diferenciadas, descontos exclusivos e um aplicativo para consultar saldos, limites e faturas, tudo de forma simples e prática." },
        { id: 61, question: "A solução inclui um aplicativo personalizado para os clientes?", answer: "Sim, o Cartão Loja inclui um aplicativo exclusivo com a marca da empresa, onde os clientes podem gerenciar suas contas, consultar saldos, limites e faturas, além de acessar promoções e benefícios." },
        { id: 62, question: "Como é feita a gestão do Cartão Loja pela empresa?", answer: "A empresa tem acesso a uma plataforma completa e personalizada, onde pode acompanhar transações, emitir relatórios, gerenciar limites de crédito e configurar campanhas de fidelização para os clientes." },
        { id: 63, question: "É necessário investir em infraestrutura para implementar o Cartão Loja?", answer: "Não, a solução é integrada às plataformas e sistemas da Sercred, reduzindo a necessidade de investimentos em infraestrutura. Todo o suporte técnico é fornecido pela nossa equipe." },
        { id: 64, question: "Como a minha empresa pode contratar a solução de Private Label?", answer: "Entre em contato com nosso time de especialistas ou preencha o formulário no site para que possamos entender suas necessidades e apresentar a melhor solução para o seu negócio." },
        { id: 65, question: "Qual o custo para ter um Cartão Loja?", answer: "Os custos variam de acordo com as necessidades da empresa, como quantidade de clientes, funcionalidades desejadas e volume de transações. Nossos especialistas ajudarão a personalizar a solução para o seu negócio." },
    ]
};