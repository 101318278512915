import React, { useState, useRef } from 'react';
import PartnerCarousel from './partnerCarousel';
import Footer from './footer';
import Header from './header';
import { useNavigate } from 'react-router-dom';

const About = () => {

    const navigate = useNavigate();

    const handleNavigate = (path: string, id?: string) => {
        navigate(path);
        if (id) {
            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) element.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
    };

    return (
        <div className="flex flex-col w-[100%] h-[100%]">
            <div id='inicio'>
                <Header />
            </div>
            <div className='flex dark:bg-sercredSecondBlueDark flex-col items-center md:flex-row md:p-3 lg:justify-evenly lg:w-[100%]'>
                <div className='text-sercredDarkBlue dark:text-white flex flex-col w-[85%] mt-3 mb-3 md:w-[50%] md:p-3 lg:w-[45%] lg:p-6'>
                    <h1 className='text-2xl md:text-4xl lg:text-5xl'>
                        Tudo bem, mas...
                    </h1>
                    <h1 className='text-2xl md:text-4xl lg:text-5xl'>
                        Quem somos?
                    </h1>
                    <p className='flex mt-5 mb-5 md:text-xl lg:mt-10 lg:text-2xl'>
                        Acima de tudo, somos um grupo de pessoas apaixonadas por levar tecnologia, conhecimento, novas oportunidades e gerar valor para outras pessoas!
                    </p>
                    <p className='flex mt-5 mb-5 md:text-xl lg:mt-5 lg:mb-10 lg:text-2xl'>
                        Nosso propósito é ajudá-lo a superar os desafios do seu negócio por meio das nossas soluções de pagamento!
                    </p>
                    <button onClick={() => handleNavigate('/solutions', 'inicio')} className='border border-1px border-sercredDarkBlue p-3 rounded-lg lg:w-[45%] w-[75%] flex items-center justify-evenly'>Conheça nossas soluções  <img src="Component 200.svg" alt="image-1" /></button>
                </div>
                <div className='flex dark:bg-sercredSecondBlueDark mt-3 mb-3 md:w-[50%] lg:w-[40%] lg:mb-0'>
                    <img src="portability_done.svg" alt="image-1" className='lg:h-[100%]' />
                </div>
            </div>
            <div className='flex flex-col items-center w-[100%] h-[100%]'>
                <div className='text-[#001F40] bg-white dark:bg-sercredDarkBlueDark dark:text-white flex flex-col w-[100%] p-6 lg:h-[33%]'>
                    <h1 className='text-2xl lg:text-4xl lg:mt-5'>
                        Há mais de 12 anos ajudando a transformar negócios
                    </h1>
                    <p className='flex mt-3 mb-3 lg:text-2xl lg:mb-5'>
                        Com soluções de meios de pagamento para o pequeno, médio e grande varejista, convênio e empréstimo consignados                    </p>
                </div>
                <div className='text-[#001F40] bg-[#5da0c4d3] dark:bg-[#5da0c4e1] dark:text-white flex flex-col w-[100%] p-6 lg:h-[33%]'>
                    <h1 className='text-2xl lg:text-4xl lg:mt-5'>
                        Mais de 3.000 estabelecimentos atendidos
                    </h1>
                    <p className='flex mt-3 mb-3 lg:text-2xl lg:mb-5'>
                        Com treinamentos, suporte técnico e comercial, consultorias, promoções de vendas e muito mais                    </p>
                </div>
                <div className='text-[#001F40] dark:bg-sercredSecondBlueDark dark:text-white bg-[#08417bc6] flex flex-col w-[100%] p-6'>
                    <h1 className='text-2xl lg:text-4xl lg:mt-5'>
                        Mais de R$27 milhões de receita para nossos parceiros
                    </h1>
                    <p className='flex mt-3 mb-3 lg:text-2xl lg:mb-5'>
                        Nossa rede possui cerca de 60 mil clientes ativos gerando negócios recorrentes para nossos parceiros                    </p>
                </div>
            </div>
            <div className='flex flex-col p-3 h-[100%] w-[100%] dark:bg-[#5da0c4e1] dark:text-white'>
                <div className='text-sercredDarkBlue dark:text-white flex w-[100%] justify-center items-center lg:text-4xl text-2xl mb-3 lg:mb-5 lg:mt-3'>
                    <h2>Nossos Parceiros</h2>
                </div>
                <div className='lg:mb-5'>
                    <PartnerCarousel />
                </div>
            </div>
            <div className='flex w-[100%] h-[100%]'>
                <Footer />
            </div>
        </div>
    );
};

export default About;